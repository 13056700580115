"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationTabLabel = exports.style = void 0;
const style = getComputedStyle(document.body);
exports.style = style;
var NavigationTabLabel;
(function (NavigationTabLabel) {
    NavigationTabLabel["CLIENTS"] = "clients";
    NavigationTabLabel["HOME"] = "home";
    NavigationTabLabel["PORTFOLIO"] = "portfolio";
})(NavigationTabLabel || (NavigationTabLabel = {}));
exports.NavigationTabLabel = NavigationTabLabel;
