"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = {
    name: 'FooterFixed',
    setup() {
        const year = new Date().getFullYear();
        const documentBody = (0, vue_1.ref)(document.body);
        const html = (0, vue_1.ref)(document.documentElement);
        const height = (0, vue_1.ref)(null);
        const handleScroll = (event) => {
            const footer = document.getElementById('footer-section').offsetHeight;
            const scrollPosition = event.target.scrollingElement.scrollTop;
            const total = height.value - window.innerHeight - footer;
            const footerLabel = document.getElementById('footerLabel');
            if (scrollPosition <= 55) {
                footerLabel.classList.remove('footer-label-dark-mode');
            }
            else if (scrollPosition < total) {
                footerLabel.classList.add('footer-label-dark-mode');
            }
            else if (scrollPosition > total) {
                footerLabel.classList.remove('footer-label-dark-mode');
            }
            else {
                footerLabel.classList.remove('footer-label-dark-mode');
            }
        };
        (0, vue_1.onMounted)(() => {
            window.addEventListener('scroll', handleScroll);
            height.value = Math.max(documentBody.value.scrollHeight, documentBody.value.offsetHeight, html.value.clientHeight, html.value.scrollHeight, html.value.offsetHeight);
        });
        return {
            year,
        };
    },
};
