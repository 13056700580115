"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("vue-i18n/index");
const lodash_1 = require("lodash");
function parseLocales(locales) {
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}
function loadLocaleMessages() {
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = parseLocales(locales);
    return (0, lodash_1.merge)(messages);
}
const i18n = (0, index_1.createI18n)({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
});
exports.default = i18n;
