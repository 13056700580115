"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_router_1 = require("vue-router");
exports.default = {
    name: 'App',
    setup() {
        const route = (0, vue_router_1.useRoute)();
        (0, vue_1.onMounted)(() => {
            const favicon = document.getElementById('favicon');
            favicon.href = require('../public/favicon.png');
        });
        return {
            route,
        };
    },
};
