const scrollSlide = (args) => {
  const scrollContainerEle = document.querySelector(args.container);
  const scrollItems = Array.from(document.querySelectorAll(`${args.container} ${args.item}`));

  let allowAnimation = true;
  const addAnimationClasses = () => {
    scrollItems.forEach((item) => {
      item.classList.add(args.animType);
    });
  };

  const addLoopAnimClasses = () => {
    const activeItem = scrollItems.find((item) => item.classList.contains('active'));

    let nextItem = scrollItems[scrollItems.indexOf(activeItem) + 1];
    let prevItem = scrollItems[scrollItems.indexOf(activeItem) - 1];

    if (!nextItem) {
      // eslint-disable-next-line prefer-destructuring
      nextItem = scrollItems[0];
    }

    if (!prevItem) {
      prevItem = scrollItems[scrollItems.length - 1];
    }
    let itemsProceed = 0;
    scrollItems.forEach((item) => {
      itemsProceed += 1;

      item.classList.remove('ss-move-prev');
      item.classList.remove('ss-move-next');

      if (itemsProceed === scrollItems.length) {
        nextItem.classList.add('ss-move-next');
        prevItem.classList.add('ss-move-prev');
      }
    });
  };

  const addAnimationDelay = (item) => {
    // eslint-disable-next-line no-param-reassign
    item.style.transitionDelay = `${args.delay}s`;
  };

  const addAnimationDuration = () => {
    scrollItems.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.style.transitionDuration = `${args.duration}s`;
      addAnimationDelay(item);
    });
  };

  const stopScrollAnim = () => {
    if (allowAnimation) { allowAnimation = false; }
    setTimeout(() => {
      allowAnimation = true;

      scrollItems.forEach((item) => {
        item.classList.remove('ss-moving');
      });
    }, (args.duration + args.delay) * 1000);
  };

  const changeScrollSlide = (moveDown) => {
    const activeItem = scrollItems.find((item) => item.classList.contains('active'));
    let nextItem;

    if (moveDown) {
      nextItem = scrollItems[scrollItems.indexOf(activeItem) + 1];
    } else {
      nextItem = scrollItems[scrollItems.indexOf(activeItem) - 1];
    }

    if (nextItem) {
      activeItem.classList.add('ss-moving');
      nextItem.classList.add('ss-moving');
      activeItem.classList.remove('active');
      nextItem.classList.add('active');

      if (args.uncutMove) { addLoopAnimClasses(); }
    } else {
      activeItem.classList.add('ss-moving');
      activeItem.classList.remove('active');

      if (moveDown) {
        scrollItems[0].classList.add('ss-moving');
        scrollItems[0].classList.add('active');

        if (args.uncutMove) { addLoopAnimClasses(); }
      } else {
        scrollItems[scrollItems.length - 1].classList.add('ss-moving');
        scrollItems[scrollItems.length - 1].classList.add('active');

        if (args.uncutMove) { addLoopAnimClasses(); }
      }
    }
  };

  addAnimationClasses();
  if (args.uncutMove) { addLoopAnimClasses(); }
  addAnimationDuration();

  let eventType;
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const isIe = /MSIE|Trident/.test(window.navigator.userAgent);
  if (isFirefox) {
    eventType = 'DOMMouseScroll';
  } else if (isIe) {
    eventType = 'mousewheel';
  } else {
    eventType = 'wheel';
  }
  scrollContainerEle.addEventListener(eventType, (event) => {
    const { scrollTop } = scrollContainerEle;
    const { scrollHeight } = scrollContainerEle;
    const height = scrollContainerEle.clientHeight;
    const delta = (event.wheelDelta) ? event.wheelDelta : -(event.detail || 0);
    if ((delta > 0 && scrollTop - delta <= 0) || (delta < 0 && scrollTop + height >= scrollHeight - 1)) {
      if (delta > 0) {
        if (allowAnimation) {
          stopScrollAnim();
          changeScrollSlide(false);
        }
      } else if (allowAnimation) {
        stopScrollAnim();
        changeScrollSlide(true);
      }
      event.preventDefault();
    } else {
      if (delta < 0) {
        if (allowAnimation) {
          stopScrollAnim();
          changeScrollSlide(true);
        }
      }
      event.preventDefault();
    }
  });
};

const scrollSlideArgs = {
  container: '.ss-container',
  item: '.ss-item',
  animType: 'ss-move-left',
  duration: 1,
  delay: 0,
  uncutMove: true,
};

export {
  scrollSlide,
  scrollSlideArgs,
};
