"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = {
    class: "arkyan-container header-fixed",
    id: "header-fixed"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
    key: 0,
    href: "mailto:contact@arkyan.com"
};
const _hoisted_4 = (0, vue_1.createElementVNode)("div", { class: "button-mobile" }, [
    (0, vue_1.createElementVNode)("a", null, [
        (0, vue_1.createElementVNode)("i", { class: "icon-send" })
    ])
], -1);
const _hoisted_5 = [
    _hoisted_4
];
const _hoisted_6 = {
    key: 1,
    href: "mailto:contact@arkyan.com"
};
const _hoisted_7 = { class: "button-container" };
const _hoisted_8 = { class: "button" };
const _hoisted_9 = (0, vue_1.createElementVNode)("div", { class: "slide" }, null, -1);
function render(_ctx, _cache, $props, $setup, $data, $options) {
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("header", _hoisted_1, [
        (0, vue_1.createElementVNode)("img", {
            onClick: _cache[0] || (_cache[0] =
                (...args) => ($setup.scrollTop && $setup.scrollTop(...args))),
            src: $setup.logo(),
            alt: "Logo",
            id: "logo"
        }, null, 8, _hoisted_2),
        ($setup.mobile)
            ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("a", _hoisted_3, _hoisted_5))
            : ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("a", _hoisted_6, [
                (0, vue_1.createElementVNode)("div", _hoisted_7, [
                    (0, vue_1.createElementVNode)("div", _hoisted_8, [
                        _hoisted_9,
                        (0, vue_1.createElementVNode)("a", null, (0, vue_1.toDisplayString)(_ctx.$t('wrapper.contactCTALabel')), 1)
                    ])
                ])
            ]))
    ]));
}
exports.render = render;
