"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { class: "arkyan-container home-section" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "ss-item ss-loop active" };
const _hoisted_4 = { class: "block-text" };
const _hoisted_5 = { class: "ss-item ss-loop" };
const _hoisted_6 = { class: "block-text" };
const _hoisted_7 = { class: "ss-item ss-loop" };
const _hoisted_8 = { class: "block-text" };
const _hoisted_9 = (0, vue_1.createElementVNode)("div", null, null, -1);
const _hoisted_10 = {
    key: 0,
    class: "icon-scroll-mobile"
};
const _hoisted_11 = (0, vue_1.createElementVNode)("div", { class: "scroll-down" }, null, -1);
const _hoisted_12 = [
    _hoisted_11
];
const _hoisted_13 = {
    key: 1,
    class: "icon-scroll"
};
const _hoisted_14 = (0, vue_1.createElementVNode)("span", null, null, -1);
function render(_ctx, _cache, $props, $setup, $data, $options) {
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("section", _hoisted_1, [
        (0, vue_1.createElementVNode)("div", _hoisted_2, [
            (0, vue_1.createElementVNode)("div", {
                class: "ss-container",
                onMousewheel: _cache[0] || (_cache[0] = (0, vue_1.withModifiers)(($event) => ($setup.scrollSlide($setup.scrollSlideArgs)), ["prevent"]))
            }, [
                (0, vue_1.createElementVNode)("div", _hoisted_3, [
                    (0, vue_1.createElementVNode)("div", _hoisted_4, [
                        (0, vue_1.createElementVNode)("h1", null, (0, vue_1.toDisplayString)(_ctx.$t('home.firstSlideTitle')), 1),
                        (0, vue_1.createElementVNode)("h2", null, (0, vue_1.toDisplayString)(_ctx.$t('home.firstSlideParagraph')), 1)
                    ])
                ]),
                (0, vue_1.createElementVNode)("div", _hoisted_5, [
                    (0, vue_1.createElementVNode)("div", _hoisted_6, [
                        (0, vue_1.createElementVNode)("h1", null, (0, vue_1.toDisplayString)(_ctx.$t('home.secondSlideTitle')), 1),
                        (0, vue_1.createElementVNode)("h2", null, (0, vue_1.toDisplayString)(_ctx.$t('home.secondSlideParagraph')), 1)
                    ])
                ]),
                (0, vue_1.createElementVNode)("div", _hoisted_7, [
                    (0, vue_1.createElementVNode)("div", _hoisted_8, [
                        (0, vue_1.createElementVNode)("h1", null, (0, vue_1.toDisplayString)(_ctx.$t('home.thirdSlideTitle')), 1),
                        (0, vue_1.createElementVNode)("h2", null, (0, vue_1.toDisplayString)(_ctx.$t('home.thirdSlideParagraph')), 1)
                    ])
                ])
            ], 32),
            _hoisted_9
        ]),
        ($setup.mobile)
            ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_10, _hoisted_12))
            : ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_13, [
                _hoisted_14,
                (0, vue_1.createElementVNode)("h6", null, (0, vue_1.toDisplayString)(_ctx.$t('wrapper.scrollLabel')), 1)
            ]))
    ]));
}
exports.render = render;
