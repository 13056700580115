"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const config_1 = require("@/config");
exports.default = {
    name: 'Navigation',
    components: {},
    props: {},
    setup(props, { emit }) {
        const selectedSection = (0, vue_1.ref)('home');
        const isPanelOpen = (0, vue_1.ref)(false);
        const panelOptions = () => {
            isPanelOpen.value = !isPanelOpen.value;
        };
        const SectionSelection = (selection) => {
            selectedSection.value = selection;
            isPanelOpen.value = false;
        };
        const handleScroll = (event) => {
            const iconBurger = document.getElementsByClassName('menu-icon__line');
            if (event.target.scrollingElement.scrollTop >= window.innerHeight) {
                iconBurger[0].classList.add('dark-mode');
                iconBurger[1].classList.add('dark-mode');
                iconBurger[2].classList.add('dark-mode');
            }
            else {
                iconBurger[0].classList.remove('dark-mode');
                iconBurger[1].classList.remove('dark-mode');
                iconBurger[2].classList.remove('dark-mode');
            }
        };
        (0, vue_1.watch)(() => selectedSection.value, () => {
            emit('switchSection', selectedSection.value);
        });
        (0, vue_1.watch)(() => isPanelOpen.value, () => {
            emit('isPanelOpen', isPanelOpen.value);
        });
        (0, vue_1.onMounted)(() => {
            window.addEventListener('scroll', handleScroll);
        });
        return {
            NavigationTabLabel: config_1.NavigationTabLabel,
            selectedSection,
            isPanelOpen,
            panelOptions,
            SectionSelection,
        };
    },
};
