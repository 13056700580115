"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    name: 'PortfolioSection',
    setup() {
        const windowWidth = window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth;
        const mobile = windowWidth < 960;
        return {
            mobile,
        };
    },
};
